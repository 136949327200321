.search-heading{
  padding-top: 15px;
}

.search{
  width: 100%;
  margin: 15px auto;

  .search-input{
    width: 100%;
    height: 40px;
    border: 1px solid $dark_gray3;
    border-radius: 27.5px;
    background-color: #FFFFFF;
    padding-left: 245px;
    padding-right: 67px;
    font-size: 14px;
    font-weight: $semibold;

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #757575;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: #757575;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: #757575;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: #757575;
    }
  }

  .search-select{
    height: 40px;
    width: 230px;
    font-size: 14px;
    font-weight: $semibold;
    position: absolute;
    top: 0px;
    left: 0px;

    border-radius: 6px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border: none;

    background-color: $dark_gray3;
    color: $dark_gray4;
    padding: 10px 15px;
  }

  .search-submit{
    height: 40px;
    width: 53px;
    font-size: 14px;
    position: absolute;
    top: 1px;
    right: 1px;
    background-color: #58CBD4;
    border: none;
    color: #FFF;
    outline: 0;
    cursor: pointer;
    border-radius: 0 6px 6px 0;
    &:after{
      content: ' ';
      background-image: url(/assets/img/search.svg);
      background-position: center;
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-size: 18px 18px;
    }
  }

  .dropdown{
    font-size: 14px;
    font-weight: $semibold;
    line-height: 19px;
    color: $text;
    cursor: pointer;
    padding-right: 15px;
    background-position-x: 200px;
    background-position-y: center;

    &:before {
      content: "";
      float: right;
      margin-top: 5px;
      width: 12px;
      height: 8px;
      background-position-y: center;
      background: url("/assets/img/ArrowBlueDown.svg") no-repeat 0 0;
    }

    &.rotate:before {
      -webkit-transform: rotate(180deg);     /* Chrome and other webkit browsers */
      -moz-transform: rotate(180deg);        /* FF */
      -o-transform: rotate(180deg);          /* Opera */
      -ms-transform: rotate(180deg);         /* IE9 */
      transform: rotate(180deg);             /* W3C compliant browsers */
    }

    &:hover, &:visited, &:focus, &:active{
      color: $text;
      text-decoration: none;
    }
  }

  .dropdown-popup{
    top: 34px;
    left: 0px;
    background-color: $dark_gray3;
    box-shadow: none;
    border-radius: 0px 0px 25px 25px;
    width: 230px;
    padding: 4px 15px;

    ul{
      li{
        a{
          color: $text;
        }
      }
    }
  }
}

.search-icon{
  margin: 10px auto;
  text-align: center;
}

.nothing-found{
  font-size: 14px;
  line-height: 19px;
  text-align: center;

  span:nth-child(1){
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 15px;
    font-weight: $semibold;
    color: #4A4A4A;
  }

  span:nth-child(2){
    display: inline-block;
    color: #757575;
  }
}