.btn {
  height: 55px;
  width: 100%;
  border-radius: 100px;
  background-color: #6CB644;
  border-color: transparent;
  cursor: pointer;

  &:focus{
    outline: none;
    box-shadow: none;
  }
}

a.btn{
  padding: 10px 20px;

  &:hover {
    color: #fff;
  }
}

a.btn.btn-success {
  line-height: 30px;
}

.new-order{
  height: 40px;
  color: $white;
  font-size: 14px;
  font-weight: $semibold;
  line-height: 19px;
  width: auto;
  text-align: center;
  float: right;
  margin: 20px 25px 20px 0px;
}

.link{
  height: 17px;
  color: #4BC1CA;
  font-weight: $semibold;
  font-size: 14px;
  line-height: 17px;

  &:visited, &:hover, &:active, &:visited {
    text-decoration: none;
    color: #4BC1CA;
  }
}

.light-blue-btn{
  margin-top: 30px;
  width: auto;
  height: auto;
  color: $white;
  padding: 10px;
  font-size: 14px;
  font-weight: $semibold;
  background-color: $light_blue;

  &:hover{
    color: $white;
  }
}

.red-btn{
  margin-top: 30px;
  width: auto;
  height: auto;
  color: $white;
  padding: 10px;
  font-size: 14px;
  font-weight: $semibold;
  background-color: #C6384D;

  &:hover{
    color: $white;
  }
}

.green-btn{
  margin-top: 30px;
  width: auto;
  height: auto;
  color: $white;
  padding: 10px;
  font-size: 14px;
  font-weight: $semibold;
  background-color: $green2;

  &:hover{
    color: $white;
  }
}

.btn-width-140{
  min-width: 140px;
  margin: 0 8px;
}

.new-order-btn, .new-price-request-btn{
  margin-top: 0;
  padding: 10px 20px;
}

button[disabled], button[disabled]:hover{
  background-color: #F6F4F3;
  color: #757575;
  border: 1px solid #E7E2DE;
}

.checkbox {
  width: 20px;
  height: 20px;
  position: relative;

  > * {
    position: absolute;
  }

  &-visible {
    border: 1px solid gray;
    width: 19px;
    height: 19px;
    background: #fff;
    border: 1px solid hsl(0,0,87%);
    border-radius: 3px;
    left: 20%;
  }

  > input {
    z-index: 1;
    opacity: 0;
    left: 30%;
    top: 50%;
    transform: translatex(-50%) translatey(-50%);
    display: block;
    cursor: pointer;
    width: 20px;
    height: 20px;
  }

  > input:checked + .checkbox-visible {
    @include bg("Checkmark.svg");
    background-size: 18px 14px;
    background-repeat: no-repeat;
  }
  > input:hover + .checkbox-visible {
    border-color: hsl(0,0,80%);
  }
  > input:hover:checked + .checkbox-visible {

  }
  > input:focus + .checkbox-visible {

  }
}

.checkbox-with-label{
  width: 100%;

  .checkbox-visible {
    left: 0%;
  }

  > input {
    left: 10px;
  }

  label{
    left: 25px;
    color: #4A4A4A;
    font-size: 14px;
    line-height: 17px;
    font-weight: $light !important;
  }

  label.checked{
    font-weight: $semibold !important;
  }
}

.move-btn{
  min-width: 240px;
  background-color: $white;
  color: $text;
  border-color: $dark_gray3;

  &:hover{
    color: $text;
  }

  &:before {
    content: "";
    float: right;
    margin-top: 5px;
    width: 12px;
    height: 8px;
    background-position-y: center;
    @include bg("ArrowBlueDown.svg");
  }

  &.rotate:before {
    -webkit-transform: rotate(180deg);     /* Chrome and other webkit browsers */
    -moz-transform: rotate(180deg);        /* FF */
    -o-transform: rotate(180deg);          /* Opera */
    -ms-transform: rotate(180deg);         /* IE9 */
    transform: rotate(180deg);             /* W3C compliant browsers */
  }
}

.move-btn[disabled]{
  &:before {
    filter: grayscale(100%); /* Current draft standard */
    -webkit-filter: grayscale(100%); /* New WebKit */
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%); /* Not yet supported in Gecko, Opera or IE */
    filter: url(resources.svg#desaturate); /* Gecko */
    filter: gray; /* IE */
    -webkit-filter: grayscale(1); /* Old WebKit */
  }
}

.blue-border-btn{
  width: auto;
  height: auto;
  background-color: $white;
  border: 1px solid $light_blue;
  padding: 10px 30px;
  color: #4BC1CA;
  font-size: 14px;
  font-weight: $semibold;
}

.sils-btn-type-1{
  height: 30px;
  width: 161px;
  border: 1px solid #6CB644;
  border-radius: 100px;
  background-color: transparent;

  color: #6CB644 !important;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  display: block;
  margin-left: 10px;
  line-height: 28px !important;
  text-align: center;
  cursor: pointer;
  &:hover{
    text-decoration: none;
    color: #6CB644;
  }
  &:focus{
    outline: 0;
  }
  &:disabled{
    opacity: 0.7;
    background-color: transparent;
    cursor: default;
    &:hover{
      background-color: transparent;
      border: 1px solid #6CB644;
    }
  }
}
.sils-btn-type-1.sils-btn-red{
  border: 1px solid $red;
  color: $red !important;
  &:disabled{
    opacity: 0.7;
    background-color: transparent;
    cursor: default;
    &:hover{
      background-color: transparent;
      border: 1px solid $red;
    }
  }
}
.sils-btn-type-1.sils-btn-lightblue{
  border: 1px solid $light_blue;
  color: $light_blue !important;
  &:disabled{
    opacity: 0.7;
    background-color: transparent;
    cursor: default;
    &:hover{
      background-color: transparent;
      border: 1px solid $light_blue;
    }
  }
}
.sils-btn-type-1.sils-btn-filled-green{
  border: 1px solid #6CB644;
  background-color: #6CB644;
  color: #ffffff !important;
  &:disabled{
    opacity: 0.7;
    background-color: #6CB644;
    cursor: default;
    &:hover{
      background-color: #6CB644;
      border: 1px solid #6CB644;
    }
  }
}

.sils-btn-type-1.sils-btn-filled-lightblue{
  border: 1px solid $light_blue;
  background-color: $light_blue;
  color: #ffffff !important;
  &:disabled{
    opacity: 0.7;
    background-color: $light_blue;
    cursor: default;
    &:hover{
      background-color: $light_blue;
      border: 1px solid $light_blue;
    }
  }
}