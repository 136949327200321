.errorcontainer{
  .errorheader{
    padding: 30px;
  }
  .errorblock{
    margin: 0 auto;
    width: 300px;
    .errortext{
      color: #4A4A4A;
      font-family: "Open Sans", sans-serif;
      font-size: 18px;
      line-height: 27px;
      text-align: center;

      margin-bottom: 50px;
    }
    .errornumber{
      color: #6CB644;
      font-family: "Open Sans", sans-serif;
      font-size: 144px;
      font-weight: bold;
      line-height: 196px;
      text-align: center;
    }
    .errortitle{
      color: #4A4A4A;
      font-family: "Open Sans", sans-serif;
      font-size: 36px;
      font-weight: 600;
      line-height: 27px;
      text-align: center;

      margin-bottom: 15px;
    }
    .errorbtn{
      .sils-btn-type-1{
        width: 187px;
        margin: 0 auto;
      }
    }
  }
}