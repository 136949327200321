textarea:focus, input:focus {
  outline: none;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

.regular-input {
  height: 55px;
  width: 100%;
  border: 1px solid $dark_gray3;
  border-radius: 25px;
  background-color: #FFFFFF;
  font-size: 14px;
  font-weight: $semibold;
  line-height: 19px;
  padding: 17px;
  margin-bottom: 1rem;

  &::placeholder {
    color: #757575;
    font-weight: $semibold;
  }
}

.password-input {
  margin-bottom: 0px;
}

.pass-hide {
  position: absolute;
  top: 21px;
  right: 36px;
  @include bg("ic_visibility_gray.svg");
  width: 22px;
  height: 15px;
  cursor: pointer;
}

.pass-show {
  @include bg("ic_visibility.svg");
}

.failed {
  border-color: $red !important;
  color: $red !important;

  &::placeholder {
    color: $red !important;
  }
}

.error-text {
  height: 24px;
  color: #B01A0F;
  font-size: 16px;
  font-weight: $semibold;
  line-height: 24px;
  text-align: center;
  margin-bottom: 25px;
}

.form-group.has-error {
  label {
    color: $red !important;
  }

  input {
    border-color: $red !important;
    color: $red !important;

    &::placeholder {
      color: $red !important;
    }
  }

  span {
    display: block !important;
    font-size: 16px;
    line-height: 24px;
    margin-top: 5px;
    text-align: center;
    font-weight: $semibold;
    color: $red;
  }
}

.form-group {
  span.filled {
    display: none;
  }
}

.g-recaptcha > div {
  margin: 0 auto;
}

.selectize-default {
  width: 100%;

  .selectize-input {
    border: 1px solid #E7E2DE;
    border-radius: 25px;
    padding: 18px 15px;
    color: #39393A;
    font-size: 14px;
    font-weight: 600;
    box-shadow: none;
  }

  .selectize-dropdown {
    border: 1px solid #E7E2DE;
    border-radius: 25px;
    overflow: hidden;
    margin-top: 5px;

    .selectize-dropdown-content {
      .option {
        padding: 10px 15px;
        &.active {
          color: #FFF;
          background-color: #58CBD4;
        }
      }
    }
  }
}

.selectize-order{
  width: calc(100% - 50px);
  .item{
    color: #4A4A4A;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 600;
    line-height: 14px;
    padding-left: 8px;
  }
  .selectize-input{
    border: 1px solid #E7E2DE !important;
    box-shadow: none!important;
  }
  .selectize-input:after {
    position: absolute;
    top: 0 !important;
    right: 15px;
    margin-top: 10px !important;
    width: 17px!important;
    height: 20px!important;
    background-position-y: center;
    background-image: url(../img/ArrowBlueDown.svg);
    border-style: none !important;
    -webkit-transition-duration: 0.8s;
    -moz-transition-duration: 0.8s;
    -o-transition-duration: 0.8s;
    transition-duration: 0.8s;
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    -o-transition-property: -o-transform;
    transition-property: transform;
  }
  .selectize-input.dropdown-active:after {
    position: absolute;
    top: 0 !important;
    right: 15px;
    margin-top: 10px !important;
    width: 17px!important;
    height: 20px!important;
    background-position-y: center;
    transform: rotate(180deg);
    background-image: url(../img/ArrowBlueDown.svg);
    border-style: none !important;
    -webkit-transition-duration: 0.8s;
    -moz-transition-duration: 0.8s;
    -o-transition-duration: 0.8s;
    transition-duration: 0.8s;
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    -o-transition-property: -o-transform;
    transition-property: transform;
  }
  .selectize-dropdown{
    margin-top: 10px;
    border:1px solid $light_gray;
  }
  .option{
    color: #4A4A4A;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 600;
    line-height: 14px;
    padding: 8px 10px;
  }
  .active{
    color: $light_blue;
    background-color: inherit;
  }
}

.sils-textarea-type-1{
  width: 100%;
  position: relative;
  textarea{
    width: 100%;
    min-height: 100px;
    //height: 60px;
    position: relative;
    z-index: 1;
    padding: 7px;
    border: 2px solid #F6F4F3;

    color: #4A4A4A;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;
  }
  &:after{
    content:"";
    border-top: 10px solid #ffffff;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    -webkit-transform: rotate(-45deg);
    z-index: 1;
    position: absolute;
    right: -4px;
    bottom: 4px;
    pointer-events: none;
  }
  .pull-tab {
    height: 0;
    width: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #58CBD4;
    bottom: 7px;
    right: -1px;
    z-index: 2;
    -webkit-transform: rotate(-45deg);
    position: absolute;
    pointer-events: none;
  }
  .parsley-errors-list{
    list-style: none;
    color: #ae0101;
    font-size: 14px;
    font-weight: 600;
    font-family: "Open Sans";
    position: absolute;
    left: 43%;
    padding: 0;
  }
}
.form-group-2{
  position: relative;
  label{
    color: #4A4A4A;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    margin-left: 12px;
  }
  .selectize-order{
    width: 100%;
  }
}

.selectize-control .selectize-input.disabled {
  .item{
    color: rgb(136,136,136);
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 600;
    line-height: 14px;
  }
  position: relative;
  background-color: #F6F4F3;
  border: 1px solid #E7E2DE !important;
  opacity: 1;
  position: relative;

  &:after{
    content: ' ';
    background-image: url("/assets/img/IconLocked.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    height: 20px;
    width: 20px;

    position: absolute;
    right: 10px;
    top: calc(50% - 20px) !important;
  }
}

.form-type-1{
  .sils-btn-type-1{
    width: 200px;
  }
  .col-6{
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  .col-12{
    padding-right: 15px !important;
    padding-left: 15px !important;
    hr{
      border-top: 2px dashed #E7E2DE!important;
    }
  }
  .smalltitle{
    color: #4A4A4A;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: bold;
    line-height: 19px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 13px;
  }
  .sils-input-type-1{
    padding-left: 15px;
  }
  input{
    color: #4A4A4A;	font-family: "Open Sans", sans-serif;	font-size: 13px;	font-weight: 600;	line-height: 16px;
  }
  .sils-textarea-type-2{
    textarea{
      color: #4A4A4A;	font-family: "Open Sans", sans-serif;	font-size: 13px;	font-weight: 600;	line-height: 16px;
    }
  }
}
.sils-file-input-type-1{
  label{
    color: #6CB644;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    text-align: center;

    cursor: pointer;
    display: block;
    height: 30px;
    line-height: 30px;
    border-radius: 100px;
    border: 1px solid #6CB644;
    width: 161px;

    margin-bottom: 10px;
  }
  .sils-file-input{
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  .sils-filename{
    display: none;
    color: #4A4A4A;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    line-height: 19px;
    margin-left: 40px;
    margin-bottom: 10px;

    &:before{
      content: ' ';
      display: block;
      height: 24px;
      width: 24px;
      margin-left: -30px;
      margin-top: -2px;
      position: absolute;
      background-image: url("/assets/img/IconDelete.svg");
      background-size: 18px 14px;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
    }

    a{
      font-size: 14px;
      line-height: 19px;
      color: #4A4A4A;
      text-decoration: none;
      font-family: "Open Sans", sans-serif;
      &:hover{
        text-decoration: none;
        color: #4A4A4A;
      }
    }
  }
}

.sils-textarea-type-2{
  width: 100%;
  position: relative;
  textarea{
    width: 100%;
    min-height: 100px;
    height: 100px;
    //height: 60px;
    position: relative;
    z-index: 1;
    padding: 7px;
    border: 1px solid #E7E2DE;

    color: #4A4A4A;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;
  }
  &:after{
    content:"";
    border-top: 10px solid #ffffff;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    -webkit-transform: rotate(-45deg);
    z-index: 1;
    position: absolute;
    right: -4px;
    bottom: 6px;
    pointer-events: none;
  }
  .pull-tab {
    height: 0;
    width: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #58CBD4;
    bottom: 9px;
    right: -1px;
    z-index: 2;
    -webkit-transform: rotate(-45deg);
    position: absolute;
    pointer-events: none;
  }
  .parsley-errors-list{
    list-style: none;
    color: #ae0101;
    font-size: 14px;
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
    position: absolute;
    left: 43%;
    padding: 0;
  }
}

.sils-textarea-type-2.sils-locked{
  textarea{
    color: rgb(136,136,136) !important;
    -webkit-text-fill-color: #888;
    position: relative;
    background-color: #F6F4F3;
    border: 1px solid #E7E2DE !important;
  }
  &:after{
    border-color: #f7f4f4;
    right: 1px;
    bottom: 7px;
    transform: rotate(180deg);
  }
}

.fileinput-container{
  background-color: #FAF9F8;
  padding-top: 22px;
  padding-bottom: 22px;
  padding-left: 3px;
  min-height: 244px;
  .filledformtext{
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.sils-counter-type-1{
  width: 60px;
  text-align: center;
  height: 20px;
  background-color: $light_gray;
  border-radius: 3px;
  border: 1px solid $dark_gray3;
  padding: 1px 6px;
}
.parsley-errors-list{
  list-style: none;
  color: #ae0101;
  font-size: 14px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  padding-left: 0;
  text-align: center;
}
.file-input-error-window{
  .parsley-errors-list{
    text-align: left;
    padding-left: 33px;
    margin-top: -8px;
  }
}

.filledformtext{
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  padding-left: 15px;
  color: #4A4A4A;
  font-family: "Open Sans",sans-serif;
  a{
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    color: #4A4A4A;
    text-decoration: none;
    font-family: "Open Sans",sans-serif;
    &:hover{
      text-decoration: none;
      color: #4A4A4A;
    }
  }
}

.sils-dropdown-type-1{
  color: #303030;
  font-family: inherit;
  font-size: 13px;
  line-height: 18px;
  -webkit-font-smoothing: inherit;
  background-color: #ffffff;
  border: 1px solid #E7E2DE!important;
  width: 250px;
  text-align: left;
  margin-left: 10px;
  &:focus{
    outline: 0;
  }
  &:after{
    content: ' ';
    position: absolute;
    top: 0;
    right: 15px;
    margin-top: 5px;
    width: 17px;
    height: 20px;
    background-position-y: center;
    background-image: url(../img/ArrowBlueDown.svg);
    border-style: none;
    transition-duration: .8s;
    transition-property: transform;
  }
}
.sils-dropdown-active{
  &:after{
    content: ' ';
    position: absolute;
    top: 0;
    right: 15px;
    margin-top: 5px;
    width: 17px;
    height: 20px;
    background-position-y: center;
    background-image: url(../img/ArrowBlueDown.svg);
    border-style: none;
    transform: rotate(180deg);
    transition-duration: .8s;
    transition-property: transform;
  }
}
.sils-dropdown-popup{
  border-radius: 0 !important;
  box-shadow: none;
  top: 45px !important;
  border: 1px solid #E7E2DE;
  padding: 8px;
  width: 250px;
  right: 0 !important;
  .sils-dropdown-li{
    a{
      color: #4A4A4A;
      font-family: "Open Sans",sans-serif;
      font-size: 13px;
      font-weight: 600;
      line-height: 14px ;
      &:hover{
        color: #4BC1CA !important;
      }
    }
  }
}
.sils-delete-type-1{
  background-color: transparent;
  border: 0;
  cursor: pointer;
  color: #C6384D;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  &:focus{
    outline: 0;
  }
  &:before{
    content: ' ';
    display: block;
    height: 24px;
    width: 24px;
    margin-left: -20px;
    position: absolute;
    background-image: url(/assets/img/IconDelete.svg);
    background-size: 18px 14px;
    cursor: pointer;
  }
}

//Error outline
.sils-input-type-1.parsley-error{
  border: 1px solid #ae0101 !important;
}
.sils-textarea-type-2{
  .parsley-error{
    border: 1px solid #ae0101 !important;
  }
}

.stickypopup{
  position: fixed !important;
}
.locked-paragraph{
  background-color: #F6F4F3;
  border: 1px solid #E7E2DE;
  padding: 10px 10px 50px 10px;

  color: #888888;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;

  &:after{
    content: ' ';
    background-image: url("/assets/img/IconLocked.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    height: 20px;
    width: 20px;

    position: absolute;
    right: 32px;
    bottom: 28px!important;
  }
}

.uni-comment-text{
  border: 1px solid #E7E2DE!important;
  padding-bottom: 35px !important;
  &:after{
    content: ' ';
    background-image: url("/assets/img/IconLocked.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    height: 20px;
    width: 20px;

    position: absolute;
    right: 32px;
    bottom: 28px!important;
  }
}