.login-box{
  height: 440px;
  width: 500px;
  border-radius: 25px;
  background-color: #F6F4F3;
  padding: 30px;
  margin: 60px auto;

  h2{
    font-size: 25px;
    font-weight: $semibold;
    line-height: 37px;
    color: #39393A;
    text-align: center;
    margin-bottom: 30px;
  }

  .pw_recovery_success{
    font-size: 16px;
    text-align: center;
    line-height: 27px;
    color: $text;
    //margin-top: 30px;
    margin-bottom: 20px;
  }
}