.profile-heading{
  h2{
    color: $text;
    font-size: 18px;
    font-weight: $semibold;
    line-height: 24px;
    margin-top: 20px;
    margin-left: 15px;
  }

  h3{
    color: $text;
    font-size: 16px;
    font-weight: $semibold;
    line-height: 17px;
    margin-top: 20px;
    margin-left: 15px;
  }
}

.profile-addresses{
  .checkbox-with-label{
    margin-top: 15px;
  }
}