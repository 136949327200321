.category-container{
  padding: 15px 30px 0px 15px;

  .table-heading{
    background-color: $light_orange;
    color: white;
    font-weight: $semibold;
    font-size: 12px;
    padding: 5px 15px;

    div:nth-child(n+2){
      text-align: center;
    }

    div{
      display: table;
      height: 50px;

      div{
        display: table-cell;
        vertical-align: middle;
      }
    }
  }

  .product-row{
    border-bottom: 2px solid $light_gray;

    &:hover{
      background-color: $light_gray;
    }

    .product{
      a{
        text-decoration: none;

        &:hover, &:focus, &:active, &:visited{
          text-decoration: none;
        }

        .product-img{
          float: left;
          margin: 15px;

          img{
            border: 1px solid $dark_gray3;
          }
        }

        .product-description{
          margin: 15px 5px;
          color: #4A4A4A;
          line-height: 17px;

          .product-name{
            font-size: 14px;
            font-weight: $semibold;
            text-transform: uppercase;
          }

          .product-sn{
            margin-top: 5px;
            font-size: 13px;
            font-weight: $semibold;
            text-transform: uppercase;
          }

          .product-specs{
            margin-top: 5px;
            font-size: 12px;
          }
        }
      }
    }

    .product-availability{
      text-align: center;
      img{
        padding-top: 40px;
      }
    }

    .product-price{
      text-align: center;
      padding-top: 38px;
    }

    .amount-unfinished-orders{
      text-align: center;
      padding-top: 38px;
    }

    .product-count{
      text-align: center;
      .minus{
        margin-right: 10px;
        margin-left: -15px;
      }

      .plus{
        margin-left: 10px;
      }

      input{
        width: 60px;
        text-align: center;
        height: 25px;
        margin-top: 38px;
        background-color: $light_gray;
        border-radius: 3px;
        border: 1px solid $dark_gray3;
        padding: 1px 6px;
      }
    }

    .add-product-container{
      text-align: center;

      .add-product{
        margin-left: -15px;
        margin-top: 26px;
        background-color: $green2;
        border-radius: 100px;
        width: 90px;
        padding: 10px;
        font-size: 14px;
        font-weight: $semibold;
        line-height: 19px;
        text-align: center;
        color: $white;
        border: none;
        outline: none;
        cursor: pointer;
      }
    }

    .product-files{
      border-top: 2px dashed $dark_gray3;
      padding-bottom: 20px;
      padding-top: 6px;

      a{
        @include bg("PDF.svg");
        font-size: 14px;
        font-weight: $semibold;
        line-height: 17px;
        color: #4BC1CA;
        display: block;
        background-size: 15px 15px;
        margin: 2px auto;
        margin-left: 15px;
        padding-left: 20px;

        &:active, &:hover, &:focus, &:visited{
          color: #4BC1CA;
        }
      }
    }
  }
}