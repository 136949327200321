nav{
  height: calc(100% - 45px);
  width: $nav-width;
  background-color: $light_gray;
  display: inline-block;
  position: absolute;
  z-index: 1;

  ul{

    text-decoration: none;
    list-style-type: none;
    padding-left: 0;
    padding-top: 10px;

    li{
      a{
        color: #4A4A4A;
        display: block;

        &:focus, &:active, &:visited{
          text-decoration: none;
          color: #4A4A4A;
        }

        &:hover{
          text-decoration: none;
          color: $light_blue;
        }

        font-size: 14px;
        font-weight: $semibold;
        line-height: 19px;
        padding: 10px 0 10px 10px;
      }

      .submenu-header{
        &:before{
          display: none;
        }
      }

      .active{
        position: relative;/*it important to set this to relative to be able to use :before in absolute*/
        color: #58CBD4 !important;

        &:hover, &:focus, &:active, &:visited{
          color: $white;
        }

        background-color: transparent;

        &:before {
          content: '';
          position: absolute;
          left: 100%;
          top: 10%;
          height: 80%;
          width: 3px;
          margin-left: -5px;
          border-right: 5px solid #58CBD4;
        }
      }


      .submenu{
        background-color: #ECECEC;
        ul{
          padding: 0 0 0 0;

          li{

            a{
              padding: 5px 10px 5px 10px;
              font-size: 12px;

              &.submenu-active{
                color: #58CBD4;
                position: relative;

                &:before {
                  content: '';
                  position: absolute;
                  left: 100%;
                  top: 0;
                  height: 100%;
                  width: 3px;
                  margin-left: -5px;
                  border-right: 5px solid #58CBD4;
                }

              }

            }

          }

        }

      }

    }
  }
}

.discount-icon{
  background-image: url("/assets/img/offer.svg");
  color: transparent;
  background-position: center;
  width: 100%;
  height: 100%;
  display: block;
  background-size: 21px 21px;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.discount-title{
  color: #4A4A4A;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
}
.discount-text{
  color: #4A4A4A;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
}
.discount-highlight{
  color: #6CB644;
}
.zerostate-container{
  display: flex;
  height: 500px;

  .zerostate-content{
    margin: auto;

    .zerostatetitle{
      text-align: center;
      margin-top:20px;

      color: #4A4A4A;
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
    }
    .zerostatedesc{
      color: #888888;
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      margin-top: 20px;
      a{
        color: #58CBD4;
        font-weight: bold;
        text-decoration: none;
        &:focus{
          text-decoration: none;
          color: #58CBD4;
        }
      }
    }
  }
}
