header {
  font-size: 16px;
  font-weight: $bold;
  color: $white;
  //height: 80px;
  height: 44px;
  background-color: #6CB644;
  width: 100%;
  padding: 15px 30px;

  .logo{
    margin-top: 15px;
    display: inline-block;
    width: 200px;
    height: 100%;
    @include bg("logo.svg");
  }
  .logocontainer{
    display: inline-block;
    height: 200%;
    margin-top: -20px;
  }

  .lang-switch{
    float: right;
    width: 60px;
    padding-left: 20px;
    border-left: #A6D694 1px solid;
    height: 100%;

    .option-heading{
      line-height: 21px;
      margin-top: -3px;

      div{
        display: inline-block;
      }

      .arrow-down, .arrow-up{
        @include bg("ic_expand_more.svg");
        background-size: contain;
        width: 12px;
        height: 8px;
        margin-bottom: 2px;
        margin-left: 3px;
      }

      .arrow-up{
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
      }

      .current-language{
        font-size: 16px;
        font-weight: $semibold;
        line-height: 14px;
      }

      cursor: pointer;
    }

    .option-content{
      line-height: 21px;

      a{
        color: $white;
        cursor: pointer;

        &:hover{
          text-decoration: underline;
        }

        &:active, &:hover, &:visited{
          color: $white;
        }
      }
    }
  }

  .user-menu{
    float: right;
    margin-right: 20px;
    height: 100%;

    .option-heading{
      text-align: right;
      margin-top: -6px;

      div{
        display: inline-block;
      }
      .user-description{
        font-size: 10px;
        text-align: right;
        font-weight: $regular;
        margin-bottom: 5px;
        display: inline-block;
        //cursor: default;

        &.current-address {
          font-size: 16px;
          margin-bottom: 0;
        }
      }

      .arrow-down, .arrow-up{
        @include bg("ic_expand_more.svg");
        background-size: contain;
        width: 12px;
        height: 8px;
        margin-bottom: 2px;
        margin-left: 3px;
        margin-right: -4px;
      }

      .arrow-up{
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
      }

      .current-user{
        color: #FFFFFF;
        font-family: "Open Sans", sans-serif;
        font-size: 12px;
        font-weight: bold;
        line-height: 14px;
        //font-size: 16px;
        //font-weight: $semibold;
        margin-bottom: 8px;
        margin-left: 20px;
        display: inline-block;
      }

      //cursor: pointer;
    }

    .option-content{
      position: relative;
      margin-top: 20px;
      font-weight: $regular;
      height: auto;
      width: auto;
      text-align: left;
      padding: 15px 25px;
      border-radius: 25px 0 25px 25px;
      background-color: $green2;
      box-shadow: 1px 0 5px 0 #363636;
      z-index: 9999;

      a{
        color: $white;
        font-size: 16px;
        font-weight: $semibold;
        line-height: 28px;
      }

      .user-description {
        font-size: 10px;
        text-align: right;
        margin-right: 25px;
        font-weight: $regular;
      }
    }
  }

  .user-tools {
    float: right;
    margin-right: 12px;
    height: 100%;
    padding-left: 12px;
    border-left: #A6D694 1px solid;

    a {
      color: #fff;
      margin: 0 8px;
      background-size: 25px 22px;
      background-position: center top;
      padding-top: 28px;
      position: relative;
      top: 22px;
      font-weight: 400;


      &:hover {
        text-decoration: none;
      }

      &.user-tools-logout {
        @include bg("user-logout.svg");
      }
      &.user-tools-profile {
        @include bg("user-profile.svg");
      }
    }
  }
}

.order-popup{
  height: 80px;
  background-color: #4A4A4A;

  .no-orders{
    .no-orders-msg{
      float: left;
      color: $white;
      padding-top: 30px;
      margin-left: 20px;
      font-size: 12px;
    }
  }

  .orders{
    font-size: 12px;
    color: $white;
    line-height: 18px;

    .popup-value{
      font-weight: $semibold;
    }

    .dark-text{
      color: #757575;
    }

    .col-10{
      margin-top: 18px;

      .row{
        div:nth-child(1){
          margin-left: 30px;
        }

        &:nth-child(2){
          margin-top: 8px;

          .dropdown{
            color: #4BC1CA;
            font-size: 14px;
            font-weight: $semibold;
            @include bg("ArrowBlueDown.svg");
            background-size: 12px 8px;
            padding-right: 25px;
            background-position: right center;

            &:hover{
              text-decoration: none;
            }
          }

          .dropdown-none {
            background-image: none;
            cursor: text;
          }
        }
      }
    }
  }
}