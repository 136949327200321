body {
  font-family: 'Open Sans', sans-serif;
}

$light: 300;
$regular: 400;
$semibold: 600;
$bold: 700;

$nav-width: 180px;

.uppercase {
  text-transform: uppercase;
}