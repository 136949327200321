
@mixin breakpoint($point) {
  @if $point == lg {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $point == md {
    @media (min-width: 992px) {
      @content;
    }
  } @else if $point == sm {
    @media (min-width: 768px) {
      @content;
    }
  }@else if $point == xs {
    @media (min-width: 320px) {
      @content;
    }
  } @else if $point == xs-only {
    @media (max-width: 768px) {
      @content;
    }

  }
}

@mixin bg ($file) {
    background-image: url("../img/#{$file}");
}

@mixin clearfix() {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}