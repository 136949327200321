.warranty-table{

  .table-heading{
    div:nth-child(5){
      text-align: left;
    }

    div:nth-child(4){
      margin-right: 2%;
    }

    div:nth-child(6){
      text-align: center;
      margin-right: 2%;
    }
  }

  .table-row{
    div:nth-child(5){
      text-align: left;
    }

    div:nth-child(4), div:nth-child(6){
      margin-right: 2%;
    }

    .warranty-id{
      font-size: 14px;
      font-weight: $semibold;

      a{
        color: #4BC1CA;

        &:hover, &:visited, &:focus, &:active{
          color: #4BC1CA;
          text-decoration: none;
        }
      }
    }

    .product-name, .product-id, .warranty-from, .warranty-to{
      font-size: 13px;
      color: #4A4A4A;
    }

    .warranty-status{
      text-align: center;
    }
  }
}

.new-warranty-heading{
  h2{
    color: $text;
    font-size: 18px;
    font-weight: $semibold;
    line-height: 24px;
    margin-top: 20px;
    margin-left: 15px;
  }
}

//Animation for when additional details are added
@keyframes fadeinthebox {
  0%   {opacity: 0}
  100% {opacity: 1}
}


.needed-details-container{
  animation-name: fadeinthebox;
  animation-duration: 0.7s;

  background-color: #FAF9F8;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  .needed-details-part-container{
    height: 100%;
    padding-bottom: 1rem;
    .col-6{
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .align-items-end{
      height: 100%;
    }
  }
}