.catalogue-container{
  padding: 15px 30px 0px 15px;

  .categories-row{

    a {
      display: block;
      margin: 15px;

      &:hover, &:visited, &:active, &:visited {
        text-decoration: none;
      }

      .category {
        border: 1px solid $dark_gray3;

        img {
          width: 100%;
        }

        div {
          height: 50px;
          background-color: $dark_gray3;
          padding: 6px 0;
          text-align: center;
          font-size: 13px;
          line-height: 19px;
          font-weight: $semibold;
          color: $text;
        }
      }
    }
  }
}