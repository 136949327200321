
.order-inner-container {
  padding: 15px 30px 15px 15px;
  background-color: #F6F4F3;
  margin-top: 10px;
  margin-bottom: 20px;
  .back-row {
    [data-action="create"] {
      width: auto;
      padding-left: 10px;
      padding-right: 10px;
    }
    .visible {
      display: block;
    }
    .invisible {
      display: none;
    }
    .selectize-order {
      width: 230px;
      padding-left: 10px;
      padding-right: 10px;
      .selectize-input {
        height: 30px;
        padding: 5px;
        &:after {
          top: -16% !important;
        }
      }
    }
  }
  .sils-input-type-1{
    &:disabled{
      border: 1px solid #E7E2DE!important;
    }
  }
}

.orderlabel {
  color: #4A4A4A;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
}

.sils-input-type-1 {
  width: 100%;
  height: 36px;
  border: 1px solid #E7E2DE !important;
  color: #4A4A4A;
  font-size: 13px;
  line-height: 14px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  padding-left: 8px;

  &:disabled {
    color: rgb(136,136,136) !important;
    -webkit-text-fill-color: #888;
    position: relative;
    background-color: #F6F4F3;
    border: 1px solid #E7E2DE !important;

    //&:before{
    //  content: ' ';
    //  background-image: url("/assets/img/IconLocked.svg");
    //  background-size: 20px 20px;
    //  background-position: center;
    //  background-repeat: no-repeat;
    //
    //  height: 20px;
    //  width: 20px;
    //
    //  position: absolute;
    //  right: 10px;
    //  top: calc(50% - 10px);
    //}
  }
}

.sils-input-type-1:disabled + .lock {
  background-image: url("/assets/img/IconLocked.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  height: 20px;
  width: 20px;

  display: block;
  position: absolute;
  right: 25px;
  top: 37px;
  //position: absolute;
  //right: 10px;
  //top: calc(50% - 10px);
}

.orders-container {
  padding: 15px 30px 0 15px;

  .filters-link-container {
    a {
      color: #4BC1CA;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      text-align: right;
      display: block;
      margin-top: 34px;

      &:before {
        content: "";
        float: right;
        margin-top: 5px;
        margin-left: 8px;
        width: 12px;
        height: 8px;
        background-position-y: center;
        background: url("/assets/img/ArrowBlueDown.svg") no-repeat 0 0;
      }

      &.rotate:before {
        -webkit-transform: rotate(180deg); /* Chrome and other webkit browsers */
        -moz-transform: rotate(180deg); /* FF */
        -o-transform: rotate(180deg); /* Opera */
        -ms-transform: rotate(180deg); /* IE9 */
        transform: rotate(180deg); /* W3C compliant browsers */
      }

      &:hover, &:visited, &:focus, &:active {
        color: #4BC1CA;
        text-decoration: none;
      }
    }
  }

  .other-filters {
    display: none;
  }

  .new-order-row {
    //padding-bottom: 15px;
    //border-bottom: 2px solid $light_gray;
    .sils-btn-filled-lightblue {
      width: 200px;
      margin-left: auto;
    }
  }

  .order-filter {
    h2 {
      margin: 15px;
      font-size: 18px;
      font-weight: $semibold;
      line-height: 24px;
      color: $text;
    }

    ul {

      list-style-type: none;
      text-align: center;
      li {
        display: inline-block;
        font-size: 14px;
        font-weight: $semibold;
        line-height: 19px;

        a {
          color: $text;

          &:hover, &:focus, &:active, &:visited {
            color: $text;
            text-decoration: none;
          }
        }
      }

      li:nth-child(n+2) {
        margin-left: 30px;
      }

      .active {
        a {
          color: #5CAB35;
          &:hover, &:focus, &:active, &:visited {
            color: #5CAB35;
            text-decoration: none;
          }
        }
      }
    }
  }

  .table-heading {
    background-color: $light_orange;
    color: white;
    font-weight: $semibold;
    font-size: 14px;
    line-height: 17px;
    padding: 5px 15px;

    div:nth-child(3) {
      text-align: center;
    }

    div:nth-child(6) {
      text-align: right;
    }

    div {
      display: table;
      height: 50px;

      div {
        display: table-cell;
        vertical-align: middle;
      }
    }
  }

  .order-row {
    padding: 20px 15px;
    border-bottom: 2px solid $light_gray;
    line-height: 1em;

    div:nth-child(3) {
      text-align: center;
    }

    .order-id {
      font-size: 14px;
      font-weight: $semibold;

      a {
        color: #4BC1CA;

        &:hover, &:visited, &:focus, &:active {
          color: #4BC1CA;
          text-decoration: none;
        }
      }
    }

    .order-date, .order-supply-date, .order-price {
      font-size: 13px;
      color: #4A4A4A;
    }

    .order-status {
      font-size: 14px;
      font-weight: $semibold;

      .unfinished {
        color: #EF8F5F;
      }

      .processing {
        color: #4BC1CA;
      }

      .partially-fulfilled {
        color: #4BC1CA;
      }

      .fulfilled {
        color: #5CAB35;
      }
    }

    .order-actions {
      .edit-link {
        float: right;
      }

      .delete-link {
        float: right;
      }
    }
  }

  .order-heading {
    h2 {
      color: $text;
      font-weight: $semibold;
      font-size: 18px;
      line-height: 24px;
      margin: 15px 0;
    }
  }
}

.single-order-table {
  .table-heading {
    .checkbox-heading {
      display: none;
    }

    div:nth-child(3) {
      text-align: left;
    }

    div:nth-child(4) {
      text-align: center;
    }

    div:nth-child(5) {
      text-align: center;
    }

    div:nth-child(6) {
      text-align: left;
    }

    div:nth-child(7) {
      text-align: left;
    }

    div:nth-child(8) {
      text-align: center;
    }
  }

  .order-row {
    div:nth-child(3) {
      text-align: left;
    }

    div:nth-child(4) {
      text-align: center;
    }

    .checkbox {
      display: none;
    }

    .order-name {
      font-size: 13px;
      color: #4A4A4A;
    }

    .order-count {
      text-align: center;
      .minus {
        margin-right: 10px;
        //margin-left: -15px;
      }

      .plus {
        margin-left: 10px;
      }

      input {
        width: 60px;
        text-align: center;
        height: 20px;
        background-color: $light_gray;
        border-radius: 3px;
        border: 1px solid $dark_gray3;
        padding: 1px 6px;
      }
    }

    .order-actions {
      .delete-link {
        margin-left: 45%;
        float: left;
      }
    }
  }
}

.back-order-row {
  .dropdown-popup {
    right: 10px;
    top: 55px;
    background-color: $white;
    min-width: 240px;
    left: unset;

    ul {
      li {
        a {
          color: $text;

          &:active, &:focus, &:hover, &:visited {
            color: $text;
          }
        }
      }
    }
  }
}

.success-popup {
  .popup-buttons {
    .yes-btn {
      display: block;
      margin: 0 auto;
      float: none;
    }
  }
}

.order-address {
  font-size: 10px;
  color: #888;
  display: block;
}