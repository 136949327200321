.search-date{
  border: 1px solid $dark_gray3;
  border-radius: 5px;
  margin-bottom: 15px;
  width: 330px;
  padding: 17px 15px;
  font-size: 14px;
  font-weight: $semibold;
  line-height: 19px;
  color: $text;
  cursor: pointer;
  height: 30px;
  @include bg("icon-date.svg");
  background-size: auto;
  background-position-x: 291px;
  background-position-y: -67px;

  ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: #757575;
  }
  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #757575;
    opacity:  1;
  }
  ::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #757575;
    opacity:  1;
  }
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #757575;
  }
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #757575;
  }

  ::placeholder { /* Most modern browsers support this now. */
    color: #757575;
  }
}

.date-input{
  border: 1px solid $dark_gray3;
  border-radius: 25px;
  margin-bottom: 15px;
  padding: 17px 15px;
  font-size: 14px;
  font-weight: $semibold;
  line-height: 19px;
  color: $text;
  cursor: pointer;

  @include bg("IconDate.svg");
  background-size: auto;
  background-position: right 18px center;

  ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: #757575;
  }
  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #757575;
    opacity:  1;
  }
  ::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #757575;
    opacity:  1;
  }
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #757575;
  }
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #757575;
  }

  ::placeholder { /* Most modern browsers support this now. */
    color: #757575;
  }
}

.daterangepicker{
  border: none;
  margin-top: 5px;
  border-radius: 6px;
  box-shadow: 1px 0 5px 0 #363636;
  padding: 15px;
  z-index: 2;

  .prev, .next{
    color: #4BC1CA !important;
    font-size: 12px;

    &:hover{
      color: #4BC1CA !important;
    }
  }

  &:before{
    top: 13px;
    border: 16px solid #ffffff;
    left: 41.4%!important;
    transform-origin: 0 0;
    transform: rotate(-45deg);
    box-shadow: 1px 0 5px 0 #363636;
    z-index: -1;
  }
  &:after{
    left: 40.5%!important;
    top: 0px;
    height: 36px;
    width: 51px;
    background-color: #fff;
    z-index: -1;
  }

  .cancelBtn{
    display: none;
  }

  .applyBtn{
    float: right;
    width: 166px;
    height: 40px;

    background-color: transparent;
    border-color: #6CB644;

    color: #6CB644;
    height: 35px;
  }
  table{
    margin-top: -10px;
  }

  .ranges{
    clear: both;
    float: right !important;
  }

  .calendar.left{
    border-right: 1px solid #E8E8E8;
  }

  .calendar{
    max-width: unset;
    th, td{
      padding: 8px 15px;
    }
  }

  .daterangepicker_input{
    display: none;
  }

  .calendar-table{
    table{
      color: #39393A;

      thead{
        font-size: 16px;
        line-height: 22px;
        font-weight: $semibold;

        tr:nth-child(2){
          font-size: 12px;
          line-height: 17px;
          font-weight: $light;
        }
      }

      tbody{
        font-size: 12px;
        line-height: 17px;

        tr{
          td{
            &.start-date, &.end-date{
              background-color: $light_blue !important;
            }

            &.in-range{
              background-color: rgba(88,203,212,0.5);
            }
          }
        }
      }
    }
  }
}

.calendar.left.single{
  border-right: none;
}