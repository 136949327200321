.product-container {
  padding: 15px 30px 0px 15px;

  .page-heading{
    margin-top: 15px;
  }

  .product-gallery{
    margin-top: 30px;

    .main-image{
      border: 1px solid $light_gray;

      img{
        width: 100%;
      }
    }

    .gallery-images{
      margin-top: 15px;
      text-align: center;

      div{
        img{
          cursor: pointer;
          border: 1px solid $light_gray;
          width: 90%;

          &.active{
            border: 3px solid $green2;
          }
        }
      }
    }
  }

  .product-description{
    margin-top: 30px;
    color: $text;

    .product-info{
      margin-left: 30px;
      font-size: 14px;
      font-weight: $semibold;
      border-bottom: 2px dashed $dark_gray3;
      padding-bottom: 15px;

      .product-name{
        margin-bottom: 5px;
        text-transform: uppercase;
      }

      .product-sn{
        margin-bottom: 5px;

        .sn{
          text-transform: uppercase;
        }
      }

      .product-price{
        color: $light_blue;
        margin-bottom: 5px;
        .attribute{
          color: $text;
        }
      }
    }

    .product-specs{
      margin-left: 30px;
      margin-top: 15px;
      padding-bottom: 15px;
      font-size: 12px;
      line-height: 17px;
      border-bottom: 2px dashed $dark_gray3;
    }
    .product-discount{
      border-radius: 3px;
      background-color: #F6F4F3;
      padding-left:50px;
      padding-top:10px;
      padding-bottom:10px;
    }
    .product-discount-title{
      color: #4A4A4A;
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
      margin-bottom:5px;
      &:before{
        content: ' ';
        background-image: url("/assets/img/offer.svg");
        background-size: 20px 20px;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        height: 20px;
        width: 20px;
        display: block;
        margin-left: -34px;
      }
    }
    .product-discount-desc{
      font-family: "Open Sans", sans-serif;
      font-size: 12px;
      font-weight: 600;
      line-height: 22px;
    }
    .product-files{
      margin-top: 15px;
      margin-left: 30px;
      padding-bottom: 15px;
      border-bottom: 2px dashed $dark_gray3;

      a{
        font-size: 14px;
        font-weight: $semibold;
        line-height: 17px;
        color: #4BC1CA;
        display: block;
        background-image: url('/assets/img/PDF.svg');
        background-size: 15px 15px;
        margin: 2px auto;
        padding-left: 20px;

        &:active, &:hover, &:focus, &:visited{
          color: #4BC1CA;
        }
      }
    }

    .product-count{
      margin-left: 30px;
      margin-bottom: 30px;

      span{
        font-weight: $semibold;
        font-size: 14px;
        line-height: 19px;
      }

      .minus{
        margin-right: 10px;
      }

      .plus{
        margin-left: 10px;
      }

      input{
        width: 60px;
        text-align: center;
        height: 25px;
        margin-top: 15px;
        background-color: $light_gray;
        border-radius: 3px;
        border: 1px solid $dark_gray3;
        padding: 1px 6px;
      }
    }

    .add-to-basket{
      margin-left: 30px;

      .add-to-basket-btn{
        border: none;
        border-radius: 100px;
        background-color: $green2;
        color: $white;
        font-size: 14px;
        font-weight: $semibold;
        line-height: 19px;
        padding: 10px 20px;
        outline: none;
        cursor: pointer;
      }
    }
  }
}