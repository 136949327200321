.empty-invoices{
  margin-top: 30px;

  .empty-txt{
    color: $text2;
    margin-top: 30px;
    text-align: center;
    font-weight: $semibold;
    font-size: 14px;
  }
}

.table-row{
  .invoice-id{
    font-size: 14px;
    font-weight: $semibold;

    a{
      color: #4BC1CA;

      &:hover, &:visited, &:focus, &:active{
        color: #4BC1CA;
        text-decoration: none;
      }
    }
  }

  .invoice-supply-date, .invoice-pay-date, .invoice-address, .invoice-price{
    font-size: 13px;
    color: #4A4A4A;
  }

  .invoice-status {
    font-size: 14px;
    font-weight: $semibold;

    .unpaid {
      color: #EF8F5F;
    }

    .paid {
      color: #5CAB35;
    }
  }
}