.uni-table{
  .uni-table-heading{
    .checkbox-heading{
      display: none;
    }
  }
  .checkbox{
    display: none;
  }
  .order-name{
    font-size: 13px;
    color: #4A4A4A;
  }
  [data-input="quantity"]{
    width: 60px;
    text-align: center;
    height: 25px;
    background-color: $light_gray;
    border-radius: 3px;
    border: 1px solid $dark_gray3;
    padding: 1px 6px;
  }
  .minus{
    margin-right: 10px;
    //margin-left: -15px;
  }

  .plus{
    margin-left: 10px;
  }



  .order-actions{
    .delete-link{
      margin-left: 45%;
      float: left;
    }
  }
  .comment-btn{
    background-color: transparent;
    border: 0;
    cursor: pointer;
    background-image: url("/assets/img/comment.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    width: 23px;
    height: 20px;
    margin: 0 auto;
    display: block;
    &:focus{
      outline: 0;
    }
  }
  .comment-btn.commentactive{
    background-image: url("/assets/img/comment_active.svg");
  }
  a {
    color: #4BC1CA;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    display: block;
    &:hover, &:visited, &:focus, &:active{
      color: #4BC1CA;
      text-decoration: none;
    }
  }
  .uni-table-row{
    padding: 20px 15px;
    border-bottom: 2px solid $light_gray;

    font-size: 13px;
    color: #4A4A4A;
  }

  .uni-align-center{
    text-align: center;
  }
  .uni-align-right{
    text-align: right;
  }
  .d-flex{
    margin-top:10px;
    margin-bottom: 10px;
  }
  .uni-comment-container{
    background-color: #F6F4F3;
    padding-top: 20px;
    width: 103%;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    position: relative;
    padding-right: 15px;
    margin-bottom: -20px;
    margin-top: 15px;
    display: none;
    &:before{
      content: "";
      position: absolute;
      top: 0;
      left: 26.5%;
      margin-left: -5px;
      border-width: 9px;
      border-style: solid;
      border-color: #fff transparent transparent;
    }
    .sils-textarea-type-1{
      textarea{
        min-height: 60px;
        height: 90px;
      }
    }
  }
  .uni-comment-text{
    color: #888888;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;

    background-color: transparent;
    min-height: 100px;
    padding: 7px;
    border: 1px solid #b2b0af;
    border-radius: 3px;

    margin-bottom:20px;
  }
  .right-action-menu{
    display: none;
  }
  [data-save="comment"]{
    display: none;
  }
  .comment-already-exists{
    [data-submit="comment"]{
      display: none;
    }
    textarea{
      padding-right: 40px;
    }
    .right-action-menu{
      position: absolute;
      z-index: 3;
      background-color: #e6e2df;
      border: 1px solid #e6e2df;
      border-right: 1px solid transparent;
      right: 1px;
      margin-top: 1px;
      height: calc(100% - 4px);
      display: block;
      .row{
        height:100%;
        .col-12{
          display: flex;
          span{
            margin: auto;
          }
        }
      }
      .comment-edit{
        background-image: url("/assets/img/edit_comment.svg");
        background-repeat: no-repeat;
        background-size: 16px 16px;
        background-position: center;
        display: block;
        height: 16px;
        width: 16px;
        cursor: pointer;
      }
      .comment-edit.comment-edit-active{
        background-image: url("/assets/img/EditActive.svg");
      }
      .comment-delete{
        background-image: url("/assets/img/delete_comment.svg");
        background-repeat: no-repeat;
        background-size: 16px 16px;
        background-position: center;
        display: block;
        height: 16px;
        width: 16px;
        cursor: pointer;
      }
    }
    textarea{
      &:disabled{
        padding-right: 40px;
        background-color: transparent;
        border: 1px solid #b2b0af;
      }
    }
  }
  .uni-table-heading{
    background-color: $light_orange;
    color: white;
    font-weight: $semibold;
    font-size: 14px;
    line-height: 17px;
    padding: 5px 15px;

    div{
      display: table;
      height: 50px;

      div{
        display: table-cell;
        vertical-align: middle;
      }
    }
  }
}
