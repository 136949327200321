/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}

body {
  max-width: 1440px;
  margin: 0 auto;
  min-width: 1024px;

  //margin-bottom: 50px;
  /* Margin bottom by footer height */
  @include breakpoint(sm) {
    //margin-bottom: 50px;
  }

  &.no-footer, &.error-page {
    margin-bottom: 0;
  }
}
.content{
  display: inline-block;
  position: relative;
  padding-left: $nav-width;
  width: 100%;
  //margin-bottom: 50px;

  .page-heading{
    color: $dark_gray4;
    font-size: 18px;
    font-weight: $semibold;
    line-height: 24px;
    margin-bottom: 0;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
  }
}

.container{
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}

.no-sidebar{

}

.clearfix {
  @include clearfix;
}

.relative {
  position: relative;
}

*, *:before, *:after {
  background-repeat: no-repeat;
  background-size: 100% auto;
}

.topbottom15{
  margin: 15px 0;
}

.main-container{
  width: 100%;
}

.row {
  margin-left: 0;
  margin-right: 0;
  [class*="col-"] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.pager{
  text-align: center;
  margin: 30px auto;

  a{
    display: inline-block;
    margin: auto 5px;

    &:active, &:hover, &:focus, &:visited{
      text-decoration: none;
    }
  }

  .prev-ico{
    @include bg('BackInactive.svg');
    background-size: 8px 12px;
    width: 8px;
    height: 12px;
  }

  .page-ico{
    padding: 11px 14px;
    color: $dark_gray2;
    background-color: $white;
    font-size: 13px;
    font-weight: $semibold;
    line-height: 14px;
    border-radius: 12px;
    border: 1px solid $dark_gray3;

    &:hover{
      color: $light_blue;
      border-color: $light_blue;
    }
  }

  .current-page-ico{
    padding: 11px 14px;
    color: $white;
    background-color: $light_blue;
    font-size: 13px;
    font-weight: $semibold;
    line-height: 14px;
    border-radius: 12px;
  }

  .next-ico{
    @include bg('PageForward.svg');
    background-size: 8px 12px;
    width: 8px;
    height: 12px;
  }

  .first-ico{
    @include bg('PageLast.svg');
    background-size: 16px 12px;
    width: 16px;
    height: 12px;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .last-ico{
    @include bg('PageLast.svg');
    background-size: 16px 12px;
    width: 16px;
    height: 12px;
  }
}

.back-row{
  padding: 10px 0px 25px 0px;
  //border-bottom: 2px solid $light_gray;

  &.back-order-row{
    padding: 10px 0px 15px 0px;
  }

  .back-order{
    display: inline-block;
  }

  a{
    display: block;
    color: $light_blue;
    font-size: 14px;
    line-height: 19px;
    font-weight: $semibold;

    &:active, &:visited, &:hover, &:focus{
      color: $light_blue;
    }

    &.btn {
      color: #fff;
      &:active, &:visited, &:hover, &:focus{
        color: #fff;
      }
    }
  }

  .back-order{
    color: $light_blue;

    &:active, &:visited, &:hover, &:focus{
      color: $light_blue;
    }

    &:before{
      content: "";
      float: left;
      width: 10px;
      height: 10px;
      margin: 4px 6px 0 0px;
      background-size: contain;
      @include bg("PageForward.svg");
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
}

.minus, .plus{
  cursor: pointer;
}

.dropdown-popup{
  //overflow-y: -webkit-paged-y;
  overflow: visible;
  display: none;
  position: absolute;
  z-index: 2;
  border-radius: 25px 0 25px 25px;
  background-color: #4A4A4A;
  box-shadow: 1px 0 5px 0 #363636;
  padding: 20px 25px;
  left: -20px;

  .dropdown-delimiter{
    background-color: rgba(136,136,136,0.5);
    height: 1px;
    margin-bottom: 15px;
  }

  ul{
    list-style-type: none;
    padding: 0px;
    margin: 0px;

    li{
      font-size: 13px;
      font-weight: $semibold;
      color: #4BC1CA;
      margin-top: 15px;

      &:nth-child(1){
        margin-top: 0px;
      }

      a{
        text-decoration: none;
        color: #4BC1CA;

      &:hover, &:visited, &:focus, &:active{
        text-decoration: none;
        color: #4BC1CA;
      }
      }
    }
  }
}

.lightdropdown{
  .dropdown-none{
    &:before{
      display: none !important;
    }
  }
  .dropdown{
    color: #4BC1CA;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: right;
    //display: block;
    margin-top: 34px;
    position: relative;

    &:before {
      content: "";
      //float: right;
      //margin-top: 9px;
      //margin-right: 10px;
      right: 0;
      position: absolute;
      margin-top: 9px;
      margin-right: -19px;
      width: 12px;
      height: 8px;
      background-position-y: center;
      background: url("/assets/img/ArrowBlueDown.svg") no-repeat 0 0;
      -webkit-transition-duration: 0.8s;
      -moz-transition-duration: 0.8s;
      -o-transition-duration: 0.8s;
      transition-duration: 0.8s;
      -webkit-transition-property: -webkit-transform;
      -moz-transition-property: -moz-transform;
      -o-transition-property: -o-transform;
      transition-property: transform;
    }

    &.rotate:before {
      -webkit-transform: rotate(180deg);     /* Chrome and other webkit browsers */
      -moz-transform: rotate(180deg);        /* FF */
      -o-transform: rotate(180deg);          /* Opera */
      -ms-transform: rotate(180deg);         /* IE9 */
      transform: rotate(180deg);             /* W3C compliant browsers */
    }

    &:hover, &:visited, &:focus, &:active{
      color: #4BC1CA;
      text-decoration: none;
    }
  }
  .dropdown-popup{
    background-color: #f5f5f5;
  }
}
.order-inner-label-column{
  padding-right: 5px;
  color: $text2;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: bold;
  line-height: 26px;
}
.order-inner-adress{
  color: #4A4A4A;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: bold;
  line-height: 17px;
  margin-top: 8px;
}
.order-inner-text-small{
  color: #888888;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
}
.order-inner-highlight{
  color: $light_blue;
}
.textalignright{
  text-align: right;
}

.finish-order{
  top: 7px;
  right: -28px;
  padding: 15px;
  width: 236px;
  margin-left: auto;

  background-color: #F6F4F3;
  border-radius: 5%;
  .added-to-order{
    color: #363636;
    font-family: "Open Sans";
    font-size: 15px;
    font-weight: $semibold;
    line-height: 22px;
  }

  a{
    color: #020001 !important;
    padding-right: 10px;
    width: 100%;

    &:hover, &:active, &:focus, &:visited{
      color: $white;
      text-decoration: none;
    }

    .popup-content{
      margin-top: 15px;
    }

    .product-description{
      margin-left: 10px;
      margin-right: -10px;
      line-height: 17px;
    }

    .popup-product-title{
      font-size: 15px;
      font-weight: $semibold;
    }

    .popup-product-sn{
      font-size: 13px;
      font-weight: $semibold;
    }

    .popup-product-count, .popup-product-price{
      font-weight: $light;
      font-size: 13px;
    }
  }


  .open-order{
    background-color: $light_blue;
    border-radius: 100px;
    padding: 10px;
    outline: none;
    border: none;
    cursor: pointer;
    width: 100%;
    font-size: 14px;
    line-height: 19px;
    font-weight: $semibold;
    color: $white;
  }

  .delimiter{
    background-color: $light_gray;
    height: 2px;
    margin: 15px auto;
  }
}

.rotate-180{
  -webkit-transform: rotate(180deg);     /* Chrome and other webkit browsers */
  -moz-transform: rotate(180deg);        /* FF */
  -o-transform: rotate(180deg);          /* Opera */
  -ms-transform: rotate(180deg);         /* IE9 */
  transform: rotate(180deg);             /* W3C compliant browsers */
}

.popup{
  &.without-heading{
    .popup-heading {
      border-bottom: none;
    }

    .popup-text{
      text-align: center;
      font-size: 16px;
      font-weight: $semibold;
      line-height: 17px;
    }
  }

  display: none;
  position: absolute;
  width: 450px;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 0 20px 0 rgba(74,74,74,0.8);
  background-color: $white;
  color: $text;
  z-index: 99999;
  top: 170px;
  left: 40%;

  .popup-heading{
    font-size: 16px;
    font-weight: $semibold;
    line-height: 17px;
    padding-bottom: 20px;
    border-bottom: 1px solid #D8D8D8;

    .close-popup{
      display: inline-block;
      @include bg("IconClose.svg");
      float: right;
      border: none;
      outline: none;
      height: 14px;
      width: 14px;
      background-color: $white;
      cursor: pointer;
    }
  }

  .popup-text{
    font-size: 16px;
    line-height: 24px;
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .popup-buttons{
    max-width: 390px;

    .btn{
      height: 40px;
      width: 180px;
      color: $white;
      font-weight: $semibold;
      font-size: 14px;
      line-height: 19px;
      display: inline-block;
    }

    .yes-btn{
      background-color: $green2;
      float: right;
    }

    .no-btn{
      background-color: #C6384D;
    }
  }
}

.no-border{
  border: none !important;
}

.top-15{
  margin-top: 15px;
}

.top-30{
  margin-top: 30px;
}

.bottom-15{
  margin-bottom: 15px;
}

.heading-filter{
  h2{
    margin: 15px;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    color: $text;
  }

  ul{
    margin-bottom: 30px;
    list-style-type: none;
    text-align: center;
    li{
      display: inline-block;
      font-size: 14px;
      font-weight: $semibold;
      line-height: 19px;

      a{
        color: $text;

        &:hover, &:focus, &:active, &:visited{
          color: $text;
          text-decoration: none;
        }
      }
    }

    li:nth-child(n+2){
      margin-left: 30px;
    }

    .active{
      a{
        color: #5CAB35;
        &:hover, &:focus, &:active, &:visited{
          color: #5CAB35;
          text-decoration: none;
        }
      }
    }
  }
}

.table-row{
  padding: 20px 15px;
  border-bottom: 2px solid $light_gray;
  line-height: 1em;
}

.default-container{
  padding: 15px 30px 10px 15px;

  .filters-link-container{
    a{
      color: #4BC1CA;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      text-align: right;
      display: block;
      margin-top: 34px;

      &:before {
        content: "";
        float: right;
        margin-top: 5px;
        margin-left: 8px;
        width: 12px;
        height: 8px;
        background-position-y: center;
        background: url("/assets/img/ArrowBlueDown.svg") no-repeat 0 0;
      }

      &.rotate:before {
        -webkit-transform: rotate(180deg);     /* Chrome and other webkit browsers */
        -moz-transform: rotate(180deg);        /* FF */
        -o-transform: rotate(180deg);          /* Opera */
        -ms-transform: rotate(180deg);         /* IE9 */
        transform: rotate(180deg);             /* W3C compliant browsers */
      }

      &:hover, &:visited, &:focus, &:active{
        color: #4BC1CA;
        text-decoration: none;
      }
    }
  }

  .other-filters{
    display: none;
  }

  .table-heading{
    background-color: $light_orange;
    color: white;
    font-weight: $semibold;
    font-size: 14px;
    line-height: 17px;
    padding: 5px 15px;

    div:nth-child(3){
      text-align: center;
    }

    div:nth-child(6){
      text-align: right;
    }

    div{
      display: table;
      height: 50px;

      div{
        display: table-cell;
        vertical-align: middle;
      }
    }
  }
}

.new-order-row{
  //padding-bottom: 15px;
  //border-bottom: 2px solid $light_gray;
  .sils-btn-filled-lightblue{
    width: 200px;
    margin-left: auto;
    margin-top: 12px;
  }
  display: flex;
}

.height-290{
  height: 290px !important;
}

.height-440{
  height: 440px;
}

.catalogue-breadcrumb {
  color: $dark_gray4;

  &:hover {
    color: $light_blue;
    text-decoration: none;
  }
}

.active-message{
  margin-top: -13px;
  .active-msg-title{
    color: #4A4A4A;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    margin-bottom: 5px;
  }
  .active-msg-par{
    color: #888888;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
  }
}