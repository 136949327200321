.price-request-filter{
  h2{
    margin: 15px;
    font-size: 18px;
    font-weight: $semibold;
    line-height: 24px;
    color: $text;
  }

  ul{
    margin-bottom: 30px;
    list-style-type: none;
    text-align: center;
    li{
      display: inline-block;
      font-size: 14px;
      font-weight: $semibold;
      line-height: 19px;

      a{
        color: $text;

        &:hover, &:focus, &:active, &:visited{
          color: $text;
          text-decoration: none;
        }
      }
    }

    li:nth-child(n+2){
      margin-left: 30px;
    }

    .active{
      a{
        color: #5CAB35;
        &:hover, &:focus, &:active, &:visited{
          color: #5CAB35;
          text-decoration: none;
        }
      }
    }
  }
}

.price-request-row{
  padding: 20px 15px;
  border-bottom: 2px solid $light_gray;
  line-height: 1em;

  .product-id{
    font-size: 14px;
    font-weight: $semibold;

    a{
      color: #4BC1CA;

      &:hover, &:visited, &:focus, &:active{
        color: #4BC1CA;
        text-decoration: none;
      }
    }
  }

  .product-price, .price-request-date{
    color: $text;
    font-size: 13px;

  }

  .price-request-status{
    font-size: 14px;
    font-weight: $semibold;

    .processing{
      color: #EF8F5F;
    }

    .ordered{
      color: #5CAB35;
    }

    .offer{
      color: #4BC1CA;
    }

    .rejected{
      color: #757575;
    }
  }
}
.default-container {
  .table-heading {
    background-color: $light_orange;
    color: white;
    font-weight: $semibold;
    font-size: 14px;
    line-height: 17px;
    padding: 5px 15px;

    div:nth-child(3) {
      text-align: left;
    }
  }
}

.empty-price-requests{
  margin-top: 30px;

  .empty-txt{
    color: $text2;
    margin-top: 30px;
    text-align: center;
    font-weight: $semibold;
    font-size: 14px;
  }
}

.price-request-heading{
  h2{
    color: $text;
    font-size: 18px;
    font-weight: $semibold;
    line-height: 24px;
    margin-top: 20px;
    margin-left: 15px;
  }
}

.form-container{
  border-bottom: 2px dashed $dark_gray3;
  padding-bottom: 30px;
  margin-bottom: 23px;

  .form-group{
    margin: 7px 15px;

    label{
      color: $text;
      font-size: 14px;
      font-weight: $semibold;
      line-height: 19px;
    }

    input, textarea{
      border: 1px solid $dark_gray3;
      border-radius: 25px;
      padding: 18px 15px;
      color: $text;
      font-size: 14px;
      font-weight: $semibold;
    }

    textarea{
      resize: none;
    }

    input[disabled], textarea[disabled]{
      background-color: $light_gray;
      @include bg("IconLocked.svg");
      background-size: 20px 32px;
      background-position: right 18px center;
      color: #757575;
      padding-right: 40px;
    }
  }
}

.add-request-form{
  margin-top: 30px;
}

.add-to-order-dropdown{
  top: 55px;
  right: 10px;
  left: unset;
}