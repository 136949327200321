.footer {
  position: fixed;
  //right: 0;
  bottom: 0;
  //left: 0;
  width: 100%;
  max-width: 1440px;
  /* Set the fixed height of the footer here */
  min-height: 50px;
  text-align: center;
  color: #757575;
  background-color: #4A4A4A;
  font-size: 12px;
  line-height: 18px;
  padding: 16px 0px;
  z-index: 4000;
}